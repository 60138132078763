<template>
  <PortalTile title="Kundenliste">
    <b-row align-h="center">
      <b-col cols="6">
        <b-button variant="primary" @click="download">Download</b-button>
      </b-col>
    </b-row>
  </PortalTile>
</template>

<script>
import axios from "axios";
import PortalTile from "@/components/fg-software/webPortals/PortalTile.vue";

export default {
  name: "DownloadTile",
  props: ['portalName'],
  components: {
    PortalTile
  },
  methods: {
    download() {
      switch(this.portalName) {
        case 'Adobe':
          this.downloadAdobeCustomerList()
          break
        case 'ArcGis':
          this.downloadArcGisCustomerList()
          break
        case 'Autodesk':
          this.downloadAutodeskCustomerList()
          break
        case 'BIMPLUS':
          this.downloadBimPlusCustomerList()
          break
        case 'Cinema 4D':
          this.downloadCinemaCustomerList()
          break
        case 'Eplan':
          this.downloadEplanCustomerList()
          break
        case 'Solibri':
          this.downloadSolibriCustomerList()
          break
      }
    },
    async downloadAdobeCustomerList() {
      let config = {
        method: 'get',
        url: 'downloads/adobe-customerList',
        responseType: 'blob'
      }
      await axios(config)
          .then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"]})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["content-disposition"].split('=')[1]
            link.click()
            URL.revokeObjectURL(link.href)
          })
    },
    async downloadArcGisCustomerList() {
      let config = {
        method: 'get',
        url: 'downloads/arcgis-customerList',
        responseType: 'blob'
      }
      await axios(config)
          .then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"]})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["content-disposition"].split('=')[1]
            link.click()
            URL.revokeObjectURL(link.href)
          })
    },
    async downloadAutodeskCustomerList() {
      let config = {
        method: 'get',
        url: 'downloads/autodesk-customerList',
        responseType: 'blob'
      }
      await axios(config)
          .then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"]})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["content-disposition"].split('=')[1]
            link.click()
            URL.revokeObjectURL(link.href)
          })
    },
    async downloadBimPlusCustomerList() {
      let config = {
        method: 'get',
        url: 'downloads/bimplus-customerList',
        responseType: 'blob'
      }
      await axios(config)
          .then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"]})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["content-disposition"].split('=')[1]
            link.click()
            URL.revokeObjectURL(link.href)
          })
    },
    async downloadCinemaCustomerList() {
      let config = {
        method: 'get',
        url: 'downloads/cinema-customerList',
        responseType: 'blob'
      }
      await axios(config)
          .then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"]})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["content-disposition"].split('=')[1]
            link.click()
            URL.revokeObjectURL(link.href)
          })
    },
    async downloadEplanCustomerList() {
      let config = {
        method: 'get',
        url: 'downloads/eplan-customerList',
        responseType: 'blob'
      }
      await axios(config)
          .then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"]})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["content-disposition"].split('=')[1]
            link.click()
            URL.revokeObjectURL(link.href)
          })
    },
    async downloadSolibriCustomerList() {
      let config = {
        method: 'get',
        url: 'downloads/solibri-customerList',
        responseType: 'blob'
      }
      await axios(config)
          .then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"]})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["content-disposition"].split('=')[1]
            link.click()
            URL.revokeObjectURL(link.href)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

